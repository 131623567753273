import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/games-icon/In-play.png";
import BotHeadIcon2 from "../assets/images/games-icon/Cricket.png";
import BotHeadIcon3 from "../assets/images/games-icon/Soccer.png";
import BotHeadIcon4 from "../assets/images/games-icon/Aviator.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon-tiger1.png";
import BotHeadIcon6 from "../assets/images/games-icon/Teen-patti.png";
import BotHeadIcon7 from "../assets/images/games-icon/roulette.png";
import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import Sportsbook from "../assets/images/games-icon/sportsbook.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsnIcon from "../assets/images/games-icon/slots.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";
import { useSelector } from "react-redux";
import SpribeIconNew from "../assets/images/games-icon/dice.png";

import EvolutionIconNew from "../assets/images/games-icon/casino-roulette.png";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header beforeheader">
      <div className="top_head">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="middle-part">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link href="/">
                            <img src={HomeIcon1} />
                            Home
                          </Nav.Link>
                          <Nav.Link href="/sign-in" className="exchange-nav">
                            <div>
                              <img src={SportsIcon1} />
                            </div>
                            Exchange
                          </Nav.Link>
                          <Nav.Link href="/betby" className="exchange-nav">
                            <div>
                              <img src={Sportsbook} />
                            </div>
                            Sportsbook
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            <img src={EzugiIcon} />
                            Ezugi
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            <img src={EvolutionIconNew} />
                            Evolution
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            <img src={SlotsnIcon} />
                            Slots
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            <img src={SpribeIconNew} />
                            Spribe
                          </Nav.Link>
                          {/* <Nav.Link href={CasinoProviders["worldcasino"]?.href}>
                            <GamesIcon />
                            World Casino
                          </Nav.Link> */}
                          {/* <Nav.Link href="/games-all">
                            <GamesIcon /> Casino
                          </Nav.Link> */}
                          <Nav.Link href="/sign-in">
                            <img src={CashierIcon1} />
                            Cashier
                          </Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
                <div className="headerRight">
                  <ul className="social">
                    <li>
                      <a
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "#"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                  </ul>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="button-primary btn_signin"
                  >
                    SIGN IN
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="button-primary btn_signup ms-2"
                  >
                    SIGN UP
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon1} alt="bottom head link icon" />
                    <span>Inplay</span>
                  </a>
                </li>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Cricket</span>
                  </a>
                </li>

                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>DragonTiger</span>
                  </a>
                </li>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon7} alt="bottom head link icon" />
                    <span>Roulette</span>
                  </a>
                </li>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon6} alt="bottom head link icon" />
                    <span>TeenPatti</span>
                  </a>
                </li>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Soccer</span>
                  </a>
                </li>
                <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon4} alt="bottom head link icon" />
                    <span>Aviator</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
